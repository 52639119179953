import portfolio from '../../assets/portfolio.json'
import ProjectCard from './ProjectCard'
import '../pages.css'

const LifePage = () => {
  const projects = portfolio.project

  return (
    <div id='projects'>
      {projects.map((el, index) => {
        return (
          <ProjectCard
            taskOwner={el.taskOwner}
            task={el.task}
            url={el.url}
            description={el.description}
            technologies={el.technologies}
          />
        )
      })}
    </div>
  )
}

export default LifePage
