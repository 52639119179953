import { useEffect } from 'react'
import portfolio from '../../assets/portfolio.json'
import CareerCard from './CareerCard'

const CareerPage = () => {
  useEffect(() => {
    document.title = 'Career'
  }, [])

  const career = portfolio.career

  return (
    <div id='career'>
      {career.map((e, i) => (
        <CareerCard
          key={i}
          employeer={e.employeer}
          title={e.title}
          dateOfHire={e.dateOfHire}
          description={e.description}
        />
      ))}
    </div>
  )
}

export default CareerPage
