import likedin from '../../assets/linkedin-svgrepo-com.svg'
import github from '../../assets/github-142-svgrepo-com.svg'
import gitlab from '../../assets/gitlab-svgrepo-com.svg'

import './footer.css'

const Footer = () => {
  return (
    <div id='footer'>
      <div className='icons'>
        <a href='https://www.linkedin.com/in/torbj%C3%B8rn-vatnelid-901810157/'>
          <img src={likedin} alt='error' width={40} />
        </a>
        <a href='https://github.com/torvat'>
          <img src={github} alt='error' width={40} />
        </a>
        <a href='https://gitlab.com/torvat'>
          <img src={gitlab} alt='error' width={40} />
        </a>
      </div>
      <div className='contact-info'>
        <p>Email: torvatnelid@gmail.com</p>
        <p>Phone: +47 410 05 659</p>
      </div>
    </div>
  )
}

export default Footer
