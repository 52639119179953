import { useEffect } from 'react'
import '../pages.css'

const HomePage = () => {
  useEffect(() => {
    document.title = 'Home'
  }, [])

  return (
    <div id='home'>
      <h2 style={{ textAlign: 'center' }}>About me</h2>
      <ul>
        <li>Student</li>
        <li>Software Developer</li>
        <li>Disc Golfer</li>
        <li>Sour Doug Baker</li>
        <li>Mixologist</li>
        <li>Gamer</li>
      </ul>
    </div>
  )
}

export default HomePage
