interface EducationCardProps {
  institusion: string
  degree: string
  name: string
  specialistaion: string
  description: string
}

function EducationCard({
  institusion,
  degree,
  name,
  specialistaion,
  description,
}: EducationCardProps) {
  return (
    <div>
      <h1>{institusion}</h1>
      <h2>{degree}</h2>
      <p>{name}</p>
      <p>{specialistaion}</p>
      <p>{description}</p>
    </div>
  )
}

export default EducationCard
