interface CareerCardProps {
  employeer: string
  title: string
  dateOfHire: string
  description: string
}

function CareerCard({ employeer, title, dateOfHire, description }: CareerCardProps) {
  return (
    <div>
      <h1>{employeer}</h1>
      <p>{title}</p>
      <p>{dateOfHire}</p>
      <p>{description}</p>
    </div>
  )
}

export default CareerCard
