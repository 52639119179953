import '../pages.css'

const LifePage = () => {
  return (
    <div id='life'>
      <h2>Thank you for visiting this small page about me!</h2>
      <p>
        I hope you now know a little more about me and some of my traits. However, you <br></br>
        probably want to know a little more about who I am.<br></br>
        <br></br>I am just a very big cat with strange behavior.. At least my cat thinks so.
        <br></br>I am a also the worlds okeyiest disc golfer, and sour doug baker. <br></br> I am a
        loving live-in partner (living together but not but not yet married). <br></br> I love
        playing with my raspberry pi, and find excitement in the small stuff.
      </p>
    </div>
  )
}

export default LifePage
