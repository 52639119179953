import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from './layout/Layout'
import HomePage from './pages/home/HomePage'
import EducationPage from './pages/education/EducationPage'
import CareerPage from './pages/career/CareerPage'
import ProjectPage from './pages/projects/ProjectPage'
import LifePage from './pages/life/LiftePage'

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route path='/' element={<HomePage />} />
          <Route path='/education' element={<EducationPage />} />
          <Route path='/career' element={<CareerPage />} />
          <Route path='/projects' element={<ProjectPage />} />
          <Route path='/life' element={<LifePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
