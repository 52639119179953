import './menu.css'
import { Link } from 'react-router-dom'
import { FaArrowRight } from 'react-icons/fa'

const Menu = () => {
  return (
    <div id='menu'>
      <FaArrowRight />
      <ul>
        <li>
          <Link to='/education'>Education</Link>
        </li>
        <li>
          <Link to='/career'>Career</Link>
        </li>
        <li>
          <Link to='/projects'>Projects</Link>
        </li>
        <li>
          <Link to='/life'>Life</Link>
        </li>
      </ul>
    </div>
  )
}

export default Menu
