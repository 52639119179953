import Footer from './footer/Footer'
import Menu from './menu/Menu'
import Profile from './profile/Profile'
import { Outlet } from 'react-router-dom'

const Layout = () => {
  return (
    <>
      <Menu />
      <Profile />
      <Footer />
      <Outlet />
    </>
  )
}

export default Layout
