import { Link } from 'react-router-dom'
import './profile.css'
import profileImage from '../../assets/me.jpg'

const Profile = () => {
  return (
    <div id='profile'>
      <Link className='profile-link' to='/'>
        Torbjørn Vatnelid
      </Link>
      <img src={profileImage} alt='error' width={400} height={475} />
    </div>
  )
}

export default Profile
