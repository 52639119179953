import '../pages.css'
import portfolio from '../../assets/portfolio.json'
import { useEffect } from 'react'
import EducationCard from './EducationCard'

const EducationPage = () => {
  useEffect(() => {
    document.title = 'Education'
  }, [])

  const education = portfolio.education

  return (
    <div id='education'>
      {education.map((e, index) => (
        <EducationCard
          key={index}
          institusion={e.institution}
          degree={e.degree}
          name={e.name}
          specialistaion={e.specialisation}
          description={e.description}
        />
      ))}
    </div>
  )
}

export default EducationPage
